import React from 'react'
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/effect-coverflow";
import styled from 'styled-components';
import mcdonald from './mcdonald.png'
import bethesda from './bethesda.png'
import adidas from './adidas.png'

const customers = [
  {
    logoUrl:mcdonald,
    title:"ADIDAS SPEEDFLOW CAMPAIGN 2022",
    subtitle:"AVR. VIEWER 5 MIO"
  },
  {
    logoUrl:adidas,
    title:"ADIDAS SPEEDFLOW CAMPAIGN 2022",
    subtitle:"AVR. VIEWER 5 MIO"
  },
  {
    logoUrl:bethesda,
    title:"ADIDAS SPEEDFLOW CAMPAIGN 2022",
    subtitle:"AVR. VIEWER 5 MIO"
  },
  {
    logoUrl:mcdonald,
    title:"ADIDAS SPEEDFLOW CAMPAIGN 2022",
    subtitle:"AVR. VIEWER 5 MIO"
  },
  {
    logoUrl:adidas,
    title:"ADIDAS SPEEDFLOW CAMPAIGN 2022",
    subtitle:"AVR. VIEWER 5 MIO"
  },
  {
    logoUrl:bethesda,
    title:"ADIDAS SPEEDFLOW CAMPAIGN 2022",
    subtitle:"AVR. VIEWER 5 MIO"
  },
  {
    logoUrl:adidas,
    title:"ADIDAS SPEEDFLOW CAMPAIGN 2022",
    subtitle:"AVR. VIEWER 5 MIO"
  },
  {
    logoUrl:bethesda,
    title:"ADIDAS SPEEDFLOW CAMPAIGN 2022",
    subtitle:"AVR. VIEWER 5 MIO"
  }
]

const SlideWrapper = styled.div`
  height:160px;
  width:160px;
  background: linear-gradient(180deg, #213C72 0%, #0C1117 100%);
  display:flex;
  justify-content:center;
  align-items:center;
`;

const Wrapper = styled.div`
  margin-top:100px;
`;


const Name = styled.h2`
  text-transform:uppercase;
  margin:0;
  font-family: "TT Norms Pro";
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.19em;
  text-align: left;

`;

const Customer = styled.h2`
  text-transform:uppercase;
  margin:0 0 20px 0 ;
  font-family: "TT Norms Pro";
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.1em;
  text-align: left;
  transform: translateX(30px);

`

const settings = {
  slidesPerView: "auto",
  spaceBetween: 30,
  breakpoints: {
  }
}

function LogoSlider(props) {
  const { name } = props;

  return (
    <Wrapper>
      <Name>{name}</Name>
      <Customer>Kunden</Customer>
      <Swiper {...settings} >
        {customers.map((customer,index)=>(
          <SwiperSlide style={{width:"160px"}}>
            <SlideWrapper>
              <img src={customer.logoUrl} alt={`${index}-customer-logo`}></img>
            </SlideWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}

export default LogoSlider