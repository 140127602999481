import styled from 'styled-components'
import React from 'react'
import exclBg from './excl-bg.png'
import Icons from '../../images/Socials/Icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CrossSrc from './Kreuz.png'
import AvatarDekoSrc from './background-circles4.png'

const TopWrapper = styled.div`
  display: flex;
  padding-top: 150px;
  padding-bottom: 100px;
  justify-content: center;
  overflow: hidden;
  gap: 50px;
  > div {
    max-width: 450px;
    width: 50%;
    transform: translateY(30px);
    @media (max-width: 600px) {
      width: auto;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap-reverse;
    padding-top: 100px;
    gap: 0;
  }
`

const Avatar = styled.img`
  width: 445px;
  height: 559px;
  border-radius: 20px;
  object-fit: cover;
  filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.25));
  @media (max-width: 600px) {
    height: 347px;
    width: 276px;
    border-radius: 10px;
  }
  margin: 0;
  padding: 0;
`

const BioWrapper = styled.div`
  color: white;
`

const Name = styled.h1`
  font-size: ${(props) => (props.length < 8 ? '70' : props.length <= 10 ? '58' : props.length < 13 ? '38' : '26')}px;
  text-transform: uppercase;
  font-weight: 430;
  font-family: 'TT Norms Pro Bold';
  margin: 0;
  color: white;
  font-weight: 430;
  line-height: 78px;
  letter-spacing: 0.09em;
  @media (max-width: 600px) {
    font-size: ${(props) => (props.length < 8 ? '70' : props.length <= 10 ? '58' : props.length < 13 ? '38' : '26')}px;
    text-align: center;
    line-height: 1.4em;
    margin-bottom: 28px;
  }
  @media (max-width: 450px) {
    font-size: ${(props) => (props.length < 8 ? '70' : props.length <= 10 ? '38' : props.length < 13 ? '38' : '26')}px;
    text-align: center;
    line-height: 1.4em;
    margin-bottom: 28px;
  }
`

const UrlWrapper = styled.a`
  display: flex;
  width: fit-content;
  height: fit-content;
`

const Bio = styled.div`
  font-family: 'TT Norms Pro';
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  @media (max-width: 600px) {
    padding: 20px;
  }
`

const Icon = styled.img`
  width: 25px;
  height: 30px;
  opacity: 0.5;
`

const ExclusiveBanner = styled.div`
  background-image: url(${exclBg});
  background-size: cover;
  display: inline-block;
  color: #1d3462;
  font-family: 'TT Norms Pro';
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.25em;
  text-align: center;
  padding: 0 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  width: 200px;
`

const SocialItem = styled.div`
  margin-bottom: 25px;
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
  }
  > h2 {
    font-family: 'TT Norms Pro';
    font-size: 10px;
    font-weight: 800;
    line-height: 12px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
  }
  > h3 {
    font-family: 'TT Norms Pro';
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
  }
`

const Socials = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15% 15% 0 10%;
  @media (max-width: 600px) {
    padding: 20px 10px 0 50px;
  }
`

const MobileOnlyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 600px) {
    display: none;
  }
`

const DesktopOnlyWrapper = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`

const DownloadButton = styled.div`
  height: 44px;
  width: 250px;
  background: white;
  margin-left: 10%;
  border-radius: 50px;
  color: #1d3462;
  display: grid;
  place-items: center;
  text-transform: uppercase;
  font-family: 'TT Norms Pro';
  font-size: 14px;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`

const Cross = styled.img`
  height: 16px;
  width: 16px;
  position: absolute;
  ${(props) => props.top && 'top: -15px;'}
  ${(props) => props.bottom && 'bottom: -15px;'}
  ${(props) => props.right && 'right: -15px;'}
  ${(props) => props.left && 'left: -15px;'}
`

const AvatarWrapper = styled.div`
  position: relative;
  display: grid;
  place-items: center;
`

const AvatarDeko = styled.img`
  position: absolute;
  left: 50%;
  top: -50%;
  transform: translateX(-50%);
  width: 380%;
  pointer-events: none;
`

const getPropertyName = (platform) => {
  if (platform === 'twitch' || platform === 'twitter' || platform === 'instagram' || platform === 'tiktok' || platform === 'tiktoktwo') {
    return 'followers'
  }
  if (platform === 'youtube' || platform === 'youtubeSecond' || platform === 'youtubeThird') {
    return 'subscribers'
  }
}

function quickFormatNumber(num) {
  if (num >= 1000000) {
    const millionNum = (num / 1000000).toFixed(1)
    return millionNum.endsWith('.0') ? millionNum.slice(0, -2) + 'Mio' : millionNum + 'Mio'
  } else if (num >= 1000) {
    const thousandNum = (num / 1000).toFixed(2)
    return thousandNum.endsWith('.00') ? thousandNum.slice(0, -3) + 'K' : thousandNum + 'K'
  } else {
    return num
  }
}

function HeroSection(props) {
  const { streamer } = props
  const { i18n } = useTranslation('')
  const lang = i18n.language

  return (
    <>
      <TopWrapper>
        <BioWrapper>
          <DesktopOnlyWrapper>
            {streamer.exclusive && <ExclusiveBanner>Exclusive Talent</ExclusiveBanner>}
            <Name length={streamer.usernameprops.characters}>{streamer.name}</Name>
          </DesktopOnlyWrapper>
          <Bio dangerouslySetInnerHTML={{ __html: streamer.bio[`bio_${lang}_html`] }} />
          {streamer.displaySocials && (
            <Socials>
              {streamer.displaySocials.map((item) => (
                <SocialItem>
                  <UrlWrapper href={streamer.socials[item].channelUrl} target="_blank">
                    <Icon src={item === 'youtubeSecond' || item === 'youtubeThird' ? Icons['youtube'] : Icons[item]} />
                  </UrlWrapper>
                  {/* <h2>{item === 'youtubeSecond' ? 'youtube #2' : item === 'youtubeThird' ? 'youtube #3' : item}</h2> */}
                  <h2>{streamer.socials[item].channelName}</h2>
                  {/* <h3>{streamer.socials[item].channelName}</h3> */}
                  <h3>
                    {quickFormatNumber(streamer.socials[item][getPropertyName(item)])} {getPropertyName(item)}
                  </h3>
                </SocialItem>
              ))}
            </Socials>
          )}
          {/* <DownloadButton>Download Sedcard</DownloadButton> //wastn wanted anymore but ready if wanted again */}
        </BioWrapper>
        <div>
          <MobileOnlyWrapper>
            {streamer.exclusive && <ExclusiveBanner>Exclusive Talent</ExclusiveBanner>}
            <Name length={streamer.usernameprops.characters}>{streamer.name}</Name>
          </MobileOnlyWrapper>

          <AvatarWrapper>
            <AvatarDeko src={AvatarDekoSrc} />
            <Cross top right src={CrossSrc} />
            <Cross top left src={CrossSrc} />
            <Cross bottom right src={CrossSrc} />
            <Cross bottom left src={CrossSrc} />
            <Avatar src={streamer.avatar.url} />
          </AvatarWrapper>
        </div>
      </TopWrapper>
    </>
  )
}

export default HeroSection
