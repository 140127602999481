import React from 'react'
import styled from 'styled-components';
import BgImg from './creator_bg-big.jpg'
const BackgroundWrapper = styled.div`
  height:100%;
  background-image:url(${BgImg});
  background-size:cover;
`;


function Background({children}) {
  return (
    <BackgroundWrapper>
      {children}
    </BackgroundWrapper>
  )
}

export default Background