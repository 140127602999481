import styled from 'styled-components'
import React from 'react'
import { CookieNotice } from 'gatsby-cookie-notice'
import Footer from '../components/Footer/Footer'
import HeroSection from '../components/StreamerTemplate/HeroSection'
import ExtendedSocials from '../components/StreamerTemplate/ExtendedSocials'
import CustomerRow from '../components/StreamerTemplate/CustomerRow'
import ContactFooter from '../components/Footer/ContactFooter'
import Background from '../components/StreamerTemplate/Background'
import TopCircle from '../components/StreamerTemplate/background_circle_corner.png'
const MobileBreakPoint = '600px'

const BottomWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  max-width: min(90%, 1220px);
  flex-direction: column;
  justify-content: center;
`

const FooterWrapper = styled.div`
  max-width: 100%;
  margin-top: 150px;
  margin-bottom: 150px;
  @media (max-width: ${MobileBreakPoint}) {
    margin: auto;
    margin-top: 80px;
    margin-bottom: 50px;
  }
`

const TopDeco = styled.img`
  position: absolute;
  pointer-events: none;
  @media (max-width: 1250px) {
    display: none;
  }
`

function Streamer(props) {
  const streamer = props.pageContext.data

  return (
    <Background>
      <CookieNotice
        personalizeButtonClasses={'personalize-button'}
        personalizeButtonText={'Individuelle Datenschutzeinstellungen'}
        backgroundWrapperClasses={'cookie-notice-wrapper bg-cookie'}
        declineButton={false}
        acceptButtonText={'Alle akzeptieren'}
        cookies={[
          {
            name: 'necessary',
            editable: false,
            default: true,
            title: 'Essential',
            text: 'Essential cookies are necessary for the proper functioning of the site. The site cannot function properly without them.',
          },
          {
            name: 'gatsby-gdpr-google-analytics',
            editable: true,
            default: true,
            title: 'Google Analytics',
            text: 'Google Analytics is a statistical tool of Google allowing to measure the audience of the website.',
          },
        ]}
      >
        <h4>Datenschutzeinstellungen</h4>
        <p>
          Wir nutzen Cookies auf unserer Website. <br />
          Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern. Wir verwenden Cookies
          und andere Technologien auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre
          Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für personalisierte Anzeigen
          und Inhalte oder Anzeigen- und Inhaltsmessung.
          <p>Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer Datenschutzerklärung.</p>
          <p>Sie können Ihre Auswahl jederzeit unter Einstellungen widerrufen oder anpassen.</p>
          <p>
            Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben möchten, müssen Sie Ihre
            Erziehungsberechtigten um Erlaubnis bitten.
          </p>
        </p>
      </CookieNotice>
      <TopDeco src={TopCircle} />
      <HeroSection streamer={streamer} />
      <BottomWrapper>
        {streamer.displaySocials && <ExtendedSocials streamer={streamer} />}
        {/* <CustomerRow name={streamer.acf.name}/> //wastn wanted anymore but ready if wanted again */}
        <FooterWrapper>
          <ContactFooter variant={'light'} />
        </FooterWrapper>
        <Footer />
      </BottomWrapper>
    </Background>
  )
}

export default Streamer
