import styled from 'styled-components'
import React from 'react'

const SocialItemWrapper = styled.div`
  position: relative;
  width: 26%;
  max-width: 26%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media (max-width: 600px) {
    width: 90%;
    max-width: 90%;
    margin: 0;
    margin-bottom: 50px;
  }
`

const Title = styled.h3`
  margin: 0 0 10px 0;
  font-family: 'TT Norms Pro';
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.19em;
  text-align: left;
  text-transform: uppercase;
  color: white;
  > p {
    margin: 0 0 0 20px;
    font-size: 34px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0.1em;
    text-align: left;
  }
`

const Kuyper = styled.div`
  margin: 10px 0 0 0;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
`

const BottomSocialsWrapper = styled.div`
  display: flex;
  ${(props) => props.spaceAround && 'justify-content: flex-start;'}
  gap: 70px 11%;
  margin-top: 100px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`

const ChannelLink = styled.a`
  text-decoration: none;
  ${(props) => props.greyedOut && 'opacity:0.5;pointer-events:none'}
  width: fit-content;
  flex-grow: 1;
  align-items: self-end;
  display: flex;
`

const ToChannelButton = styled.div`
  color: #1d3462;
  background: white;
  width: 155px;
  height: 44px;
  border-radius: 50px;
  display: grid;
  place-items: center;
  text-transform: uppercase;
  font-family: 'TT Norms Pro';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
  text-decoration: none;
`

const StatWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > p {
    font-family: 'TT Norms Pro';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    margin: 0 0 10px 0;
  }
`

function quickFormatNumber(num) {
  if (num >= 1000000) {
    const millionNum = (num / 1000000).toFixed(1)
    return millionNum.endsWith('.0') ? millionNum.slice(0, -2) + 'Mio' : millionNum + 'Mio'
  } else if (num >= 1000) {
    const thousandNum = (num / 1000).toFixed(2)
    return thousandNum.endsWith('.00') ? thousandNum.slice(0, -3) + 'K' : thousandNum + 'K'
  } else {
    return num
  }
}

const GoToChannelButton = ({ link, greyedOut }) => {
  return (
    <ChannelLink greyedOut={greyedOut} href={link} target="_blank">
      <ToChannelButton>Zum Kanal</ToChannelButton>
    </ChannelLink>
  )
}

const TwitchStats = ({ streamer }) => {
  const displayData = [
    {
      label: 'Followers',
      property: 'followers',
    },
    {
      label: 'Average Views (last 30 d.)',
      property: 'averageViewers',
    },
    {
      label: 'Hours watched',
      property: 'hoursWatched',
    },
  ]

  return (
    <>
      {displayData.map((item, index) => (
        <StatWrapper key={`twitch-stat-${index}`}>
          <p>{item.label}</p>
          <p>{quickFormatNumber(streamer.socials.twitch[item.property])}</p>
        </StatWrapper>
      ))}
      <GoToChannelButton link={`${streamer.socials.twitch.channelUrl}`} />
    </>
  )
}

const TikTokStats = ({ streamer, channelnr }) => {
  const displayData = [
    {
      label: 'Followers',
      property: 'followers',
    },
    {
      label: 'Views per post (30 day avg)',
      property: 'viewsPerPost',
    },
    {
      label: 'Like per post (30 day avg)',
      property: 'likesPerPost',
    },
  ]

  return (
    <>
      {displayData.map((item, index) => (
        <StatWrapper key={`tiktok-stat-${index}`}>
          <p>{item.label}</p>
          {console.log(streamer.socials[channelnr][item.property])}
          <p>{quickFormatNumber(streamer.socials[channelnr][item.property])}</p>
        </StatWrapper>
      ))}
      <GoToChannelButton link={`${streamer.socials[channelnr].channelUrl}`} />
    </>
  )
}

const YoutubeStats = ({ streamer, channelnr }) => {
  const displayData = [
    {
      label: 'Subscribers',
      property: 'subscribers',
    },
    {
      label: 'Views per video (30 day avg)',
      property: 'viewsPerVideo',
    },
    {
      label: 'Likes per video (30 day avg)',
      property: 'likesPerVideo',
    },
  ]

  return (
    <>
      {displayData.map((item, index) => (
        <StatWrapper key={`youtube-stat-${index}`}>
          <p>{item.label}</p>
          <p>{quickFormatNumber(streamer.socials[channelnr][item.property])}</p>
        </StatWrapper>
      ))}
      <GoToChannelButton link={`${streamer.socials[channelnr].channelUrl}`} />
    </>
  )
}

const InstagramStats = ({ streamer }) => {
  const displayData = [
    {
      label: 'Followers',
      property: 'followers',
    },
  ]

  return (
    <>
      {displayData.map((item, index) => (
        <StatWrapper key={`instagram-stat-${index}`}>
          <p>{item.label}</p>
          <p>{quickFormatNumber(streamer.socials.instagram[item.property])}</p>
        </StatWrapper>
      ))}
      <GoToChannelButton link={`${streamer.socials.instagram.channelUrl}`} />
    </>
  )
}

const TwitterStats = ({ streamer }) => {
  const displayData = [
    {
      label: 'Followers',
      property: 'followers',
    },
    {
      label: 'Tweet interactions',
      property: 'tweetInteractions',
    },
  ]

  return (
    <>
      {displayData.map((item, index) => (
        <StatWrapper key={`twitter-stat-${index}`}>
          <p>{item.label}</p>
          <p>{quickFormatNumber(streamer.socials.twitter[item.property])}</p>
        </StatWrapper>
      ))}
      <GoToChannelButton link={`${streamer.socials.twitter.channelUrl}`} />
    </>
  )
}

function Streamer(props) {
  const { streamer } = props

  return (
    <BottomSocialsWrapper spaceAround={streamer.displaySocials.length > 2}>
      {streamer.displaySocials.slice(0, 6).map((item) => (
        <SocialItemWrapper mr={streamer.displaySocials.length < 6}>
          <Title>
            {streamer.name} auf <br></br>
            <p>{item === 'youtubeSecond' || item === 'youtubeThird' ? 'youtube' : item === 'tiktoktwo' ? 'tiktok' : item}</p>
            <Kuyper>
              {item === 'twitter' || item === 'instagram'
                ? 'Account: ' + streamer.socials[item].channelName
                : 'Channel: ' + streamer.socials[item].channelName}
            </Kuyper>
          </Title>
          {item === 'twitch' && <TwitchStats streamer={streamer} />}
          {item === 'youtube' && <YoutubeStats streamer={streamer} channelnr={item} />}
          {item === 'youtubeSecond' && <YoutubeStats streamer={streamer} channelnr={item} />}
          {item === 'youtubeThird' && <YoutubeStats streamer={streamer} channelnr={item} />}
          {item === 'instagram' && <InstagramStats streamer={streamer} />}
          {item === 'tiktok' && <TikTokStats streamer={streamer} channelnr={item} />}
          {item === 'tiktoktwo' && <TikTokStats streamer={streamer} channelnr={item} />}
          {item === 'twitter' && <TwitterStats streamer={streamer} />}
        </SocialItemWrapper>
      ))}
    </BottomSocialsWrapper>
  )
}

export default Streamer
