import instagram from './insta.svg'
import twitter from './twitter.svg'
import twitch from './twitch.svg'
import youtube from './youtube.svg'
import tiktok from './tiktok.svg'
import tiktoktwo from './tiktok.svg'

const Icons = {
  instagram,
  twitter,
  youtube,
  twitch,
  tiktok,
  tiktoktwo,
}

export default Icons
